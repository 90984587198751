<template>
  <div class="iotProductForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <div class="iotDeviceForm-body">
        <div class="iotDeviceForm-t">
          <v-steps :stepData="stepData" :active="active"></v-steps>
        </div>
        <div class="iotDeviceForm-b">
          <div class="iotDeviceForm-b-l" v-if="active == 0">
            <el-form-item
              label="选择园区"
              :rules="[
                { required: true, message: '请选择园区', trigger: 'blur' },
              ]"
              prop="spaceId"
            >
              <community-select
                v-model="form.spaceId"
                specifyCollectionTypes=""
                spaceTypes="10,30"
              />
            </el-form-item>
            <el-form-item
              label="名称"
              :rules="[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ]"
              prop="name"
            >
              <v-input
                placeholder="请输入名称"
                v-model="form.name"
                :width="250"
                :maxlength="300"
              ></v-input>
            </el-form-item>
            <el-form-item
              label="分类"
              :rules="[
                { required: true, message: '请选择分类', trigger: 'change' },
              ]"
              prop="category"
            >
              <v-select
                clearable
                filterable
                :options="kindList"
                v-model="form.category"
              />
            </el-form-item>
            <el-form-item
              label="节点类型"
              :rules="[
                {
                  required: true,
                  message: '请选择节点类型',
                  trigger: 'change',
                },
              ]"
              prop="nodeType"
            >
              <v-select
                clearable
                filterable
                :options="nodeTypeList"
                v-model="form.nodeType"
              />
            </el-form-item>

            <el-form-item
              label="入网方式"
              :rules="[
                {
                  required: true,
                  message: '请选择入网方式',
                  trigger: 'change',
                },
              ]"
              prop="netType"
            >
              <v-select
                clearable
                filterable
                :options="netTypeList"
                v-model="form.netType"
              />
            </el-form-item>

            <el-form-item
              label="认证类型"
              :rules="[
                {
                  required: true,
                  message: '请选择认证类型',
                  trigger: 'change',
                },
              ]"
              prop="authType"
            >
              <v-select
                clearable
                filterable
                :options="authTypeList"
                v-model="form.authType"
              />
            </el-form-item>
          </div>
          <div class="iotDeviceForm-b-r" v-if="active == 1">
            <div class="modal">
              <div
                class="areaTimes"
                v-for="(item, index) in modalList"
                :key="index"
              >
                <div class="areaTimes-l">
                  <div class="item">
                    <div class="item-l">属性名称</div>
                    <div class="item-r">
                      <v-input
                        placeholder="请输入属性名称"
                        v-model="item.name"
                        :width="250"
                      ></v-input>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-l">属性变量名</div>
                    <div class="item-r">
                      <v-input
                        placeholder="请输入属性变量名"
                        v-model="item.identifier"
                        :width="250"
                      ></v-input>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-l">属性类型</div>
                    <div class="item-r">
                      <v-select
                        clearable
                        filterable
                        :options="valueTypeList"
                        v-model="item.dataSpecs.type"
                      />
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-l">单位</div>
                    <div class="item-r">
                      <v-input
                        placeholder="请输入单位"
                        v-model="item.dataSpecs.specs.unitName"
                        :width="250"
                      ></v-input>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-l">取值范围</div>
                    <div class="item-r">
                      <v-input
                        placeholder="初始值"
                        v-model="item.dataSpecs.specs.min"
                        :width="100"
                        type="number"
                        min="0"
                      ></v-input>
                      <span class="range">-</span>
                      <v-input
                        placeholder="结束值"
                        v-model="item.dataSpecs.specs.max"
                        :width="100"
                        min="0"
                        type="number"
                      ></v-input>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-l">是否必填</div>
                    <div class="item-r">
                      <v-select
                        clearable
                        filterable
                        :options="isFill"
                        v-model="item.required"
                      />
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-l">属性权限</div>
                    <div class="item-r">
                      <v-select
                        clearable
                        filterable
                        :options="valueAuth"
                        v-model="item.rwFlag"
                      />
                    </div>
                  </div>
                </div>
                <div class="areaTimes-r">
                  <div class="opera">
                    <v-button
                      text="删除"
                      v-if="index != 0"
                      @click="outRoom(item, index)"
                      type="warning"
                    ></v-button>
                    <v-button
                      v-if="index == modalList.length - 1"
                      text="新增"
                      @click="addRoom"
                    ></v-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="iotDeviceForm-b-r" v-if="active == 2">
            <div class="warn">
              <div
                class="areaTimes"
                v-for="(item, index) in events"
                :key="index"
              >
                <div class="areaTimes-l">
                  <div class="item">
                    <div class="item-l">事件名称</div>
                    <div class="item-r">
                      <v-input
                        clearable
                        v-model="item.eventName"
                        :width="250"
                        :maxlength="10000"
                      ></v-input>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-l">告警类型</div>
                    <div class="item-r">
                      <v-select
                        clearable
                        filterable
                        :options="warnType"
                        v-model="item.eventType"
                      />
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-l">事件内容</div>
                    <div class="item-r">
                      <v-input
                        clearable
                        type="textarea"
                        v-model="item.content"
                        :width="250"
                        :maxlength="10000"
                      ></v-input>
                    </div>
                  </div>

                  <div class="item">
                    <div class="item-l">校验规则</div>
                    <div class="item-r">
                      <v-select
                        clearable
                        :width="100"
                        filterable
                        :options="warnTypeSelect"
                        @change="selectWarnTypeKey(item)"
                        v-model="item.warnTypeKey"
                      />
                      <v-select
                        :width="100"
                        clearable
                        filterable
                        :options="markSelect"
                        @change="selectMarkSelectKey(item)"
                        v-model="item.markSelectKey"
                      />
                      <v-input
                        clearable
                        @input="selectInput(item)"
                        v-model="item.warnTypeValue"
                        :width="100"
                        :maxlength="10000"
                      ></v-input>
                      <span>或</span>
                      <v-input
                        clearable
                        type="textarea"
                        v-model="item.expression"
                        :width="250"
                        :maxlength="10000"
                      ></v-input>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-l">通知方式</div>
                    <div class="item-r">
                      <v-select
                        :width="100"
                        clearable
                        filterable
                        :options="noticeWay"
                        v-model="item.notifyConfig.type"
                      />
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-l">联系电话</div>
                    <div class="item-r">
                      <v-input
                        clearable
                        type="number"
                        v-model="item.notifyConfig.phone"
                        :width="250"
                        :maxlength="10000"
                      ></v-input>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-l">联系人</div>
                    <div class="item-r">
                      <v-input
                        clearable
                        v-model="item.notifyConfig.contacts"
                        :width="250"
                        :maxlength="10000"
                      ></v-input>
                    </div>
                  </div>
                </div>
                <div class="areaTimes-r">
                  <div class="opera">
                    <v-button
                      text="删除"
                      v-if="index != 0"
                      @click="outWarnRoom(item, index)"
                      type="warning"
                    ></v-button>
                    <v-button
                      v-if="index == events.length - 1"
                      text="新增"
                      @click="addWarnRoom"
                    ></v-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #saveBeforeSlot
        ><v-button text="上一步" @click="toBefore" v-if="active == 1">
        </v-button>
        <v-button text="下一步" @click="toAfter" v-if="active == 0"> </v-button>
        <v-button text="保存" @click="toAfter" v-if="active == 1"> </v-button>
      </template>
    </form-panel>
    <v-map
      :selectedLng="Number(form.longitude)"
      :selectedLat="Number(form.latitude)"
      :showMapDialog.sync="showMapDialog"
      @setLngAndLat="setLngAndLat"
    ></v-map>
  </div>
</template>

<script>
import {
  getProInfoUrl,
  addProList,
  getDeviceCredentials,
  getDeviceProList,
  getDeviceInfoUrl,
  proAllListUrl,
  valueListUrl,
  saveModelUrl,
} from "./api.js";
import {
  valueTypeListMap,
  valueTypeList,
  isFillMap,
  isFill,
  valueAuth,
  warnTypeMap,
  warnType,
  valueAuthMap,
  noticeWay,
  noticeWayMap,
} from "./map.js";

export default {
  name: "placeEdit",
  data() {
    return {
      warnType,
      noticeWay,
      valueAuth,
      isFill,
      valueTypeList,
      warnTypeSelect: [],
      markSelect: [],
      width: 250,
      active: 0,
      stepData: [
        { title: "产品详细信息", key: 1 },
        { title: "模型信息", key: 2 },
        // { title: "告警配置", key: 3 },
      ],
      submitConfig: {
        queryUrl: "",
        submitUrl: "",
      },
      kindList: [],
      modalList: [
        {
          name: "",
          rwFlag: "",
          createTs: new Date().getTime(),
          required: true,
          dataSpecs: {
            type: "",
            specs: {
              max: "",
              min: "",
              unit: "",
              unitName: "",
            },
          },
          identifier: "",
        },
      ],
      nodeTypeList: [],
      netTypeList: [],
      authTypeList: [],
      showMapDialog: false,
      isBefore: false,
      isAfter: true,
      deviceCredentials: "",
      productId: "",
      events: [
        {
          markSelectKey: "",
          warnTypeKey: "",
          warnTypeValue: "",
          content: "", //事件内容
          createTs: new Date().getTime(), //事件戳
          required: true, //默认传true
          eventName: "", //事件名称
          eventType: "", //告警类型
          expression: "", //校验规则
          identifier: "", //前端不传
          notifyConfig: {
            //通知配置
            type: "", //先写死 短信 ，其他不可选
            phone: "",
            contacts: "",
          },
        },
      ],
      form: {
        spaceId: "",
        eventConfig: [],
        attrConfig: [],
        id: "",
        name: "",
        category: "",
        nodeType: "",
        netType: "",
        authType: "",
      },
    };
  },
  watch: {},
  components: {},
  mounted() {
    this.getProAllListUrl("pro_category");
    this.getProAllListUrl("auth");
    this.getProAllListUrl("net");
    this.getProAllListUrl("node");
    this.getProAllListUrl("operator");
    const { id } = this.$route.query;
    this.form.id = id;
    if (id !== undefined) {
      this.submitConfig.queryUrl = getProInfoUrl({ deviceId: id });
      this.$refs.formPanel.getData({ id: id });
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    selectWarnTypeKey(item) {
      console.log(item, 990);
      item.expression =
        (item.warnTypeKey ? item.warnTypeKey : "") +
        (item.markSelectKey ? item.markSelectKey : "") +
        (item.warnTypeValue ? item.warnTypeValue : "");
      this.$forceUpdate();
    },
    selectMarkSelectKey(item) {
      item.expression =
        (item.warnTypeKey ? item.warnTypeKey : "") +
        (item.markSelectKey ? item.markSelectKey : "") +
        (item.warnTypeValue ? item.warnTypeValue : "");
      this.$forceUpdate();
    },
    selectInput(item) {
      item.expression =
        (item.warnTypeKey ? item.warnTypeKey : "") +
        (item.markSelectKey ? item.markSelectKey : "") +
        (item.warnTypeValue ? item.warnTypeValue : "");
      this.$forceUpdate();
    },
    addWarnRoom() {
      this.events.push({
        markSelectKey: "",
        warnTypeKey: "",
        warnTypeValue: "",
        content: "", //事件内容
        createTs: new Date().getTime(), //事件戳
        required: true, //默认传true
        eventName: "", //事件名称
        eventType: "", //告警类型
        expression: "", //校验规则
        identifier: "", //前端不传
        notifyConfig: {
          //通知配置
          type: "", //先写死 短信 ，其他不可选
          phone: "",
          contacts: "",
        },
      });
    },
    outWarnRoom(item, index) {
      this.events.splice(index, 1);
    },
    outRoom(item, index) {
      this.modalList.splice(index, 1);
    },
    addRoom() {
      this.modalList.push({
        name: "",
        rwFlag: "",
        createTs: new Date().getTime(),
        required: true,
        dataSpecs: {
          type: "",
          specs: {
            max: "",
            min: "",
            unit: "",
            unitName: "",
          },
        },
        identifier: "",
      });
    },
    async getValueList() {
      let params = {
        productId: this.productId,
      };
      let res = await this.$axios.get(`${valueListUrl}`, { params });
      if (res.code === 200) {
        res.data.forEach((ele) => {
          let obj = {};
          obj.label = ele.name;
          obj.value = ele.identifier;
          this.warnTypeSelect.push(obj);
        });
      } else {
        return false;
      }
    },
    async saveProWarn() {
      let params = {
        productId: this.productId,
        events: this.events,
      };
      let res = await this.$axios.post(`${saveModelUrl}`, params);
      if (res.code === 200) {
        this.$message.success("操作成功");
      } else {
        return false;
      }
    },
    async saveProModel() {
      let params = {
        productId: this.productId,
        properties: this.modalList,
      };
      let res = await this.$axios.post(`${saveModelUrl}`, params);
      if (res.code === 200) {
        this.$message.success("操作成功");
      } else {
        return false;
      }
    },
    async savePro() {
      let params = {
        authType: this.form.authType,
        category: this.form.category,
        createUser: this.$store.state.app.userInfo.id,
        description: "",
        id: this.form.id,
        isDeleted: 0,
        name: this.form.name,
        netType: this.form.netType,
        nodeType: this.form.nodeType,
        spaceId: this.form.spaceId,
        status: 0,
        updateTime: "",
        updateUser: 0,
      };
      let res = await this.$axios.post(`${addProList}`, params);
      if (res.code === 200) {
        this.productId = res.data;
      } else {
        return false;
      }
    },
    async toAfter() {
      if (this.active == 0) {
        await this.savePro();
        this.active = 1;
      } else if (this.active == 1) {
        await this.saveProModel();
        await this.getValueList();
        this.$router.go(-1);
        // this.active = 2;
      }

      // else if (this.active == 2) {
      //   await this.saveProWarn();
      //   this.$router.go(-1);
      // }
    },
    toBefore() {
      if (this.active == 0) {
      } else if (this.active == 1) {
        this.active = 0;
      } else if (this.active == 2) {
        this.active = 1;
      }
      this.submitConfig.queryUrl = getProInfoUrl({ deviceId: this.form.id });
      this.$refs.formPanel.getData({ id: this.form.id });
    },
    showMap() {
      this.showMapDialog = true;
    },
    setLngAndLat(l) {
      if (!l.lng || !l.lat) {
        return false;
      }
      this.form.extra.longitude = l.lng;
      this.form.extra.latitude = l.lat;
    },
    async getProAllListUrl(type) {
      let params = {
        code: type,
      };
      this.kindList = [];
      let res = await this.$axios.get(`${proAllListUrl}`, { params });
      if (res.code === 200) {
        if (res.data && res.data[0].children) {
          if (type == "pro_category") {
            res.data[0].children.forEach((ele) => {
              let obj = {};
              obj.label = ele.dictValue;
              obj.value = ele.dictKey;
              this.kindList.push(obj);
            });
          }
          if (type == "node") {
            res.data[0].children.forEach((ele) => {
              let obj = {};
              obj.label = ele.dictValue;
              obj.value = ele.dictKey;
              this.nodeTypeList.push(obj);
            });
          }
          if (type == "net") {
            res.data[0].children.forEach((ele) => {
              let obj = {};
              obj.label = ele.dictValue;
              obj.value = ele.dictKey;
              this.netTypeList.push(obj);
            });
          }
          if (type == "auth") {
            res.data[0].children.forEach((ele) => {
              let obj = {};
              obj.label = ele.dictValue;
              obj.value = ele.dictKey;
              this.authTypeList.push(obj);
            });
          }
          if (type == "operator") {
            res.data[0].children.forEach((ele) => {
              let obj = {};
              obj.label = ele.dictValue;
              obj.value = ele.dictKey;
              this.markSelect.push(obj);
            });
          }
        }
      }
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });

      if (this.form.nodeType != undefined) {
        this.form.nodeType = String(this.form.nodeType);
      }
      if (this.form.attrConfig) {
        this.modalList = JSON.parse(this.form.attrConfig);
        if (this.modalList.length == 0) {
          this.modalList = [
            {
              name: "",
              rwFlag: "",
              createTs: new Date().getTime(),
              required: true,
              dataSpecs: {
                type: "",
                specs: {
                  max: "",
                  min: "",
                  unit: "",
                  unitName: "",
                },
              },
              identifier: "",
            },
          ];
        }
        this.modalList.forEach((ele) => {
          if (!ele.dataSpecs) {
            ele.dataSpecs = {
              type: "",
              specs: {
                max: "",
                min: "",
                unit: "",
                unitName: "",
              },
            };
          }
        });
      }

      if (this.form.eventConfig) {
        this.events = JSON.parse(this.form.eventConfig);
        if (this.events.length == 0) {
          this.events = [
            {
              markSelectKey: "",
              warnTypeKey: "",
              warnTypeValue: "",
              content: "", //事件内容
              createTs: new Date().getTime(), //事件戳
              required: true, //默认传true
              eventName: "", //事件名称
              eventType: "", //告警类型
              expression: "", //校验规则
              identifier: "", //前端不传
              notifyConfig: {
                //通知配置
                type: "", //先写死 短信 ，其他不可选
                phone: "",
                contacts: "",
              },
            },
          ];
        }
        this.events.forEach((ele) => {
          if (!ele.notifyConfig) {
            ele.notifyConfig = {
              //通知配置
              type: "", //先写死 短信 ，其他不可选
              phone: "",
              contacts: "",
            };
          }
        });
      }
      this.$forceUpdate();
      console.log(this.modalList, "=+==");
    },
    submitBefore() {
      let target = {};
      Object.assign(target, this.form.extra);
      this.form.extra = JSON.stringify(target);
      return true;
    },
    async getDeviceCredentials(data) {
      let params = {};
      let res = await this.$axios.get(
        `${getDeviceCredentials({ deviceId: data })}`,
        {
          params,
        }
      );
      if (res.code === 200) {
        this.deviceCredentials = res.data;
      }
    },
    submitSuccess(data) {
      console.log(data, "===-----");
      this.active = 1;
      this.isBefore = true;

      this.submitConfig.submitUrl = "";
      this.getDeviceCredentials(data);
      return false;
    },
  },
};
</script>
<style lang="less" scoped>
.iotProductForm {
  box-sizing: border-box;
  height: 100%;
  .iotDeviceForm-body {
    .iotDeviceForm-b {
      padding: 40px;
    }
    .title {
      padding: 10px 40px;
    }
    .label {
      margin: 0 10px;
    }
  }

  .areaTimes {
    display: flex;
    align-items: flex-end;

    padding: 20px 0;
    border-bottom: 1px solid #eee;
    .areaTimes-l {
      margin-right: 20px;
    }
    .areaTimes-r {
      height: 46px;
      /deep/ .v-button {
        margin-right: 10px;
      }
    }
    .item {
      display: flex;
      align-content: center;
      margin: 10px 0;
      .item-l {
        display: flex;
        align-items: center;
        margin-right: 10px;
      }
      .item-r {
        display: flex;
        align-items: center;
        .range {
          margin: 0 20px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.iotDeviceForm .v-control {
}
</style>
